/*
 * @Date: 2023-04-10 14:07:05
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-19 12:14:21
 * @FilePath: \P1-M3-QRP-CODE\utils\hooks\useRegister.js
 */
import { useRequest } from 'ahooks';
import { fetchRequest } from '$CONFIG/Fatch.config';
import { Cookie, getBrowserVersion } from '$ACTIONS/util';
import { ApiPort } from '$ACTIONS/API';

const postRegister = ({ password, mobile, username, gender, dateOfBirth = '', email = '', }) => {
    let params = {
        password,
        mobile,
        username,
        gender,
        email,
        dateOfBirth,
        referrerUrl: Cookie.GetCookieKeyValue('Referer') || global.location.origin,
        currentDomain: global.location.origin,
        hidBlackbox: global.ioGetBlackbox && global.ioGetBlackbox().blackbox || '',
        hidE2: global.E2GetBlackbox().blackbox || '',
        language: 'VI-VN',
        mediaCode: Cookie.GetCookieKeyValue('Media') || null,
        affiliateCode : Cookie.GetCookieKeyValue('CO_affiliate') || '',
        referralCode: Cookie.GetCookieKeyValue('CO_affiliate') || '',
        // regWebsite: '1',
        websiteId: getBrowserVersion(),
    }
    return fetchRequest(ApiPort.Register, 'POST', params);
}


const useRegister = ({onBefore, onSuccess, onError}) => {
    return useRequest(postRegister, {
        manual: true,
        onBefore: () => {
            if (typeof onBefore === 'function') {
                onBefore();
            }
            console.log("onBefore")
        },
        onSuccess: (result, params) => {
            if (typeof onSuccess === 'function') {
                onSuccess(result, params);
            }
            console.log("onSuccess", result, params)
        },
        onError: (error) => {
            if (typeof onError === 'function') {
                onError(error);
            }
            console.log("onError", error)
        },
    });
}

export default useRegister
