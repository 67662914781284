const affDomains = {
  "betway178.biz": "B123456", //測試用
  //"vndbetway.com": "B123540", //INCIDENT-42632要求移除
  //"vietnambetway.com": "B123541", //已刪除域名
  "bway227.com": "B123952",
  "bw1230.com": "B120003",
  "bw1886.com": "B123586",
  "bongbway88.com": "B123954",
  "tiyubaba.com": "B120001",
  "bw0880.com": "B120002",
  "bw0883.com": "B123585",
  "bw88vn.com": "B123704",
}

export default affDomains;
