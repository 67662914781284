import React from 'react';
import { fetchRequest } from '$CONFIG/Fatch.config';
import { ApiPort } from '$ACTIONS/API';
import { useAuth } from '$UTILS/hooks/useAuth';
import { useRequest } from 'ahooks';

const postLogin = ({ username, password, affiliateCode = ''}) => {
    let params = {
        username,
        password,
        affiliateCode,
        currentDomain: global.location.origin,
        hidE2: global.E2GetBlackbox().blackbox || '',
        // Referrer: Cookie.GetCookieKeyValue('Referer') || global.location.origin,
        // hidBlackbox: global.ioGetBlackbox().blackbox || '',
        // language: 'VI-VN',
        // media: Cookie.GetCookieKeyValue('Media') || null,
        // referralCode: Cookie.GetCookieKeyValue('CO_affiliate') || '',
        // regWebsite: '1',
        // websiteId: getBrowserVersion(),
    }
    return fetchRequest(ApiPort.Login, 'POST', params)
}

const useLogin = ({ onBefore, onSuccess, onError }) => {
    const { onLoginSuccess } = useAuth();
    return useRequest(postLogin, {
        manual: true,
        onBefore: () => {
            if (typeof onBefore === 'function') {
                onBefore();
            }
            console.log("onBefore")
        },
        onSuccess: (result, params) => {
            if (typeof onSuccess === 'function') {
                onSuccess(result, params);
            }
            onLoginSuccess();
            console.log("onSuccess", result, params)
        },
        onError: (error) => {
            if (typeof onError === 'function') {
                onError(error);
            }
            console.log("onError", error)
        },
    });
}

export default useLogin
