import React from "react";
import useURLs from "$UTILS/hooks/useURLs";

const Logo = ({ src = "" }) => {
	const { data } = useURLs({ ready: !!src });
	return (
		<div className="logo-wrapper">
			{src && (
				<a href={data?.mainSiteUrl}>
					<img
						src={src}
						className={"qrp-header-logo"}
						style={{ cursor: data?.mainSiteUrl ? "pointer" : "auto" }}
					/>
				</a>
			)}
		</div>
	);
};

export default Logo;
