import React from "react";

const Description = ({ text, iconImage }) => {
	return (
		<div className="desc">
			<span dangerouslySetInnerHTML={{ __html: text }} className={iconImage ? "" : "full"} />
			{iconImage && <img className="icon" src={iconImage} />}
		</div>
	);
};

export default Description;
