// 用户名格式正则
export const RegName = /^[a-zA-Z0-9_]{6,14}$/;
// 密码格式正则
export const RegPassword = /(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=[\^#$@]*)([a-zA-Z0-9]([\^#$@]*))+$/;
// 电话号码格式正则
export const RegPhone = /^[1-9]\d{8,9}$/;
// 电子邮箱格式正则
export const RegEmail = /^[A-Za-z0-9_]+[a-zA-Z0-9_\.\-]{0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
//UserID
export const RegUserid = /^[a-zA-Z0-9_]{5,16}$/;
//dateOfBirth
export const RegBirthday = /^(19[0-9]{2}|200[0-5])-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;