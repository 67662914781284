/*
 * @Date: 2023-04-10 14:07:05
 * @LastEditors: simLarry
 * @LastEditTime: 2023-06-01 20:25:03
 * @FilePath: \P1-M3-QRP-CODE\actions\API.js
 */
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
export const HostApi = HostConfig.Config.HostApi;
export const QRHost = HostConfig.Config.QRHost;


export const ApiPort = {
	Banner1 : CMSURL + `/cms/qrp-m3-001-banners/public`,
	Banner2 : CMSURL + `/cms/qrp-m3-002-banners/public`,
	Banner3 : CMSURL + `/cms/qrp-m3-003-banners/public`,
	Banner4 : CMSURL + `/cms/qrp-m3-004-banners/public`,

	// 注册API
	Register: HostApi + `/api/QRP/Register`,
	// 登录
	Login : HostApi + `/api/QRP/Login` ,
	URLs: HostApi + `/api/QRP/URLs` ,
	QRCode: QRHost + `/qr/qr-code` ,
	CheckName : HostApi + `/api/Member/InfoValidity` ,
}