/*
 * @Date: 2023-04-10 14:07:05
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-19 11:33:20
 * @FilePath: \P1-M3-QRP-CODE\components\style10\HeaderForm.js
 */
import React from "react";
import { Button } from 'antd';
import { useForm } from "react-hook-form";
import CSImg from '@/CSImg'
import Logo from "@/Logo";
import useLogin from '$UTILS/hooks/useLogin';
import useURLs from "$UTILS/hooks/useURLs";
import Loading from '@/Loading';

// import { dispatchPiwik } from '$UTILS/hooks/usePiwik';

const HeaderForm = ({ logoImage, onBefore, onSuccess, onError }) => {
	const { loading, run } = useLogin({ onBefore, onSuccess, onError });
	const { register, handleSubmit, formState: { errors } } = useForm();

	const { data } = useURLs();
	const { mainSiteUrl = '', liveChatUrl = '' } = data || {};
	//?action=ForgotPassword?
	console.log('data==',data);
	const forgetPasswordURL = mainSiteUrl ? new URL(`?action=ForgotPassword`, mainSiteUrl).href : ''

	return (
		<header className="header">
			<div className="header-wrapper">
				<Logo src={logoImage} />
				<section className="header-form-wrapper">
					<form className="header-form" onSubmit={handleSubmit(run)}>
						<div className="box">
							<input type="text" className="web-input" maxLength={14} placeholder="Tên Đăng Nhập" {...register("username", { required: "Vui lòng nhập tên người dùng" })} />
							{errors.username && <span className="error-code">{errors.username.message}</span>}
						</div>

						<div className="box">
							<input type="password" className="web-input" maxLength={20} name="password" placeholder="Mật khẩu" {...register("password", { required: "xin vui lòng nhập mật khẩu" })} />
							{errors.password && <span className="error-code">{errors.password.message}</span>}
						</div>

						<div>
							<Button className="login" htmlType='submit' disabled={loading}>Đăng Nhập</Button>
							{loading && <Loading />}
						</div>
					</form>
					<a onClick={() => { global.globalGtag('style10_qrp', 'login form', 'login_topnav', 'event') }} href={mainSiteUrl} className="login mobile">Đăng Nhập​</a>
					<div className="header-action">
						<a href={forgetPasswordURL} className={'text-link'}>Không thể đăng nhập?</a>
						<a onClick={() => { global.globalGtag('Style10_QRP', 'Side nav bar', 'CS_sidenav', 'event') }} href={liveChatUrl} className="text-link" target={"_"}>
							<CSImg />
							<span>Hỗ trợ 24/7</span>
						</a>
					</div>
				</section>
			</div>
		</header>
	)

}

export default HeaderForm;
