const traceConfigs = {
    'm3-001': {
        piwik: '7078a742-a715-4867-83d9-a0a25c36b359',
        "51la": '',
        ga: '',
    },
    'm3-002': {
        piwik: '7078a742-a715-4867-83d9-a0a25c36b359',
        "51la": '',
        ga: '',
    },
    'm3-003': {
        piwik: '7078a742-a715-4867-83d9-a0a25c36b359',
        "51la":'',
        ga: '',
    },
    'm3-004': {
        piwik: '7078a742-a715-4867-83d9-a0a25c36b359',
        "51la":'',
        ga: '',
    }
}

export default traceConfigs;
