import React from "react";
import { show } from '@ebay/nice-modal-react';
import QRCode from "@/popup/QRCode";
import useURLs from "$UTILS/hooks/useURLs";

const Footer = () => {
	const { data } = useURLs({ ready: true });
	const mainSiteUrl = data?.mainSiteUrl || '';

	return (
		<>
			<footer className="footer">
				<div className="footer-wrapper">
					<ul className="sponsor">
						<li className="item">
							<div>
								<p className="sponsor-title">Nhà Tài Trợ Chính Của West Ham United</p>
								<img className="sponsor-img" src="/static/images/partner-assets/westham.png" alt="Nhà Tài Trợ Chính Của West Ham United" />
							</div>
						</li>
						<li className="item">
							<div>
								<p className="sponsor-title">Đối Tác Chính Thức Của Brighton and Hove</p>
								<img className="sponsor-img" src="/static/images/partner-assets/brighton.png" alt="Đối Tác Chính Thức Của Brighton and Hove" />
							</div>
						</li>
					</ul>
					<hr />
					<ul className="sponsor">
						<li className="item">
							<div>
								<p className="sponsor-title">Nhà Tài Trợ Chính<br />Của PSG.LGD E-sports Team</p>
								<img className="sponsor-img" src="/static/images/partner-assets/psg.png" alt="Nhà Tài Trợ Chính Của PSG.LGD E-sports Team" />
							</div>
						</li>
						<li className="item">
							<div>
								<p className="sponsor-title">Nhà Tài Trợ Chính<br />Của NiP E-sports Team</p>
								<img className="sponsor-img" src="/static/images/partner-assets/ninjas.png" alt="Nhà Tài Trợ Chính Của NiP E-sports Team" />
							</div>
						</li>
						<li className="item">
							<div>
								<p className="sponsor-title">Nhà Tài Trợ Chính<br />Của IG Dota 2 E-sports Team</p>
								<img className="sponsor-img" src="/static/images/partner-assets/ig.png" alt="Nhà Tài Trợ Chính Của IG Dota 2 E-sports Team" />
							</div>
						</li>
					</ul>
					<ul className="sponsor">
						<li className="item">
							<div>
								<p className="sponsor-title">Nhà Tài Trợ Chính Của<br />Blast Premier</p>
								<img className="sponsor-img" src="/static/images/partner-assets/blast_premier.png" alt="Nhà Tài Trợ Chính Của Brazil (Rio) ATP500" />
							</div>
						</li>
						<li className="item">
							<div>
								<p className="sponsor-title">Nhà Tài Trợ Chính<br />Của Brazil {`(Rio)`} ATP500</p>
								<img className="sponsor-img" src="/static/images/partner-assets/rio_open.png" alt="Nhà Tài Trợ Chính Của Brazil (Rio) ATP500" />
							</div>
						</li>
					</ul>
					<hr />

					<div className="partner">
						<div className="item game">
							<p className="partner-title">Đối Tác</p>
							<div>
								<img src="/static/images/partner-assets/evolution.png" alt="partner" className="partner-img" />
								<div>
									<img src="/static/images/partner-assets/kingmaker.png" alt="partner" className="partner-img" />
								</div>
								<img src="/static/images/partner-assets/in_sport.png" alt="partner" className="partner-img" />
								<div>
									<img src="/static/images/partner-assets/saba_sport.png" alt="partner" className="partner-img" />
									<img src="/static/images/partner-assets/bull.png" alt="partner" className="partner-img" />
								</div>
								<img src="/static/images/partner-assets/jili.png" alt="partner" className="partner-img" />
								{/* <img src="/static/images/partner-assets/fulla.png" alt="partner" className="partner-img" />
								// <img src="/static/images/partner-assets/spribe.png" alt="partner" className="partner-img" />
								// <img src="/static/images/partner-assets/asia_gaming.png" alt="partner" className="partner-img" />*/}
							</div>
						</div>
						<div className="item social">
							<p className="partner-title">Mạng Xã Hội</p>
							<div>
								<a className="img-footer-link" href="https://www.facebook.com/Betway-Vietnam-101388479346736">
									<img alt="" className="partner-img" src="/static/images/partner-assets/fb.png" />
								</a>
								<a className="img-footer-link" href="https://www.instagram.com/betwayvietnam/">
									<img alt="" className="partner-img" src="/static/images/partner-assets/instagram.png" />
								</a>
								<a className="img-footer-link" href="https://www.youtube.com/channel/UCXpXqoPx8H-eQqDm7qdTgeg">
									<img alt="" className="partner-img" src="/static/images/partner-assets/youtube.png" />
								</a>
								<a className="img-footer-link" href="https://www.tiktok.com/@betwayviet" target="_blank">
									<img alt="" className="partner-img" src="/static/images/partner-assets/tiktok.png" />
								</a>
								<a className="img-footer-link" href="https://zalo.me/899540738">
									<img alt="" className="partner-img" src="/static/images/partner-assets/zalo.png" />
								</a>
							</div>
						</div>
						<div className="item secure">
							<p className="partner-title">Bảo Mật</p>
							<div>
								<img src="/static/images/partner-assets/iovation.png" alt="partner" className="partner-img" />
							</div>
						</div>
						<div className="item">
							<p className="partner-title">Trách Nhiệm Người<br />Dùng</p>
							<div>
								<img src="/static/images/partner-assets/logo_egame.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/18x.png" alt="partner" className="partner-img" />
							</div>
						</div>
						<div className="item">
							<p className="partner-title">Phương Thức Thanh<br />Toán</p>
							<div>
								<img alt="partner" className="partner-img" src="/static/images/partner-assets/bankqr.png" />
								<img alt="partner" className="partner-img" src="/static/images/partner-assets/fp.png" />
								<img alt="partner" className="partner-img" src="/static/images/partner-assets/momo.png" />
								<img alt="partner" className="partner-img" src="/static/images/partner-assets/ngan_hang.png" />
								<img alt="partner" className="partner-img" src="/static/images/partner-assets/zalo_pay.png" />
							</div>
						</div>
						<div className="item support">
							<p className="partner-title">Ứng Dụng Di Động</p>
							<div>
								<img src="/static/images/partner-assets/app_store.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/android.png" alt="partner" className="partner-img" />
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer;
