

import traceConfigs from "./traceConfigs";
import initPiwik from "./items/piwik";
import init51la from "./items/la51";
import initGA from "./items/ga";
import affDomains from "./affDomains";

const setupTraces = (pageName) => {
  if (typeof window !== 'undefined') {
    console.log('===setupTraces',pageName);
    const thisConfig = traceConfigs[pageName || 'm3-001'];
    if (thisConfig) {
      if (thisConfig.piwik) {
        console.log('w',window);
        if(
          window.location.host == 'qrpm3stag.betway178.biz' ||
          window.location.host == 'localhost:8889' 
        ){
          initPiwik('89024c0d-e5a3-40ae-9fee-ad5118694501')
        }else{
          initPiwik(thisConfig.piwik);
        }
      }
      if (thisConfig['51la']) {
        init51la(thisConfig['51la']);
      }
      if (thisConfig.ga) {
        initGA(thisConfig.ga);
      }
    }

    const domainName = window.location.hostname;
    if (domainName && domainName.length > 0) {
      const mainDomain = domainName.split('.').slice(-2).join('.').toLowerCase();
      const aff_hardcode = affDomains[mainDomain];
      if (aff_hardcode) {
        localStorage.setItem('aff_hardcode', aff_hardcode);
      }
    }
  }
}

export default setupTraces;
